.box {
  position: relative;
  padding: 10px 80px;
  width: fit-content;
}

.box::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 0px 180px 180px 180px;
  padding: 2px;
  /* control the border thickness */
  background: linear-gradient(45deg, #FFBD64, #FFBD6400);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

@media(min-width:320px) and (max-width:500px){
  .box{
    padding: 10px 20px;
  }
}

/* .box:hover #getIntouchButtonArrow {
  animation: movingArrow 1.5s infinite;
}

@keyframes movingArrow {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(17px, -10px);
  }

  100% {
    transform: translate(0px, 0px);
  }
} */