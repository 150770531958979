/* .swiper-slide-next {
  border: solid red 10px;
  width: 50%;
} */
.mySwiper {
  position: relative;
}

.swiper-slide-next .slideText {
  opacity: 0;
}

.swiper-slide-prev .slideText {
  opacity: 0;
}

.swiper-slide-active .slideText {
  opacity: 1;
}

.swiper-slide {
  opacity: 0.4;
  overflow: hidden;
  transition: .7s;
  height: 400px !important;
  position: relative;
}

.swiper-slide-active {
  opacity: 1;

}