#header.sticky {
  margin-top: 10px;
  /* height: 70px; */
}

#NavLinksContainer.showMenu {
  left: 0 !important
}

#NavLinksContainer {
  left: -100% !important;
  transition: all 0.6s;
}

#menuBars.showBars {
  transform: scale(0);
}

#menuBars {
  transform: scale(1);
  transition: all 0.2s;

}

#CrossIcon.crossAnimate {
  position: absolute;
  left: 20px;

}

#CrossIcon {
  transition: all 0.6s;
  position: absolute;
  left: -100%;
  top: 30px;
  transition-delay: 0.3s;
}

#navLink.active {
  color: #F49920;
  font-size: 20px;
}

#navLink {
  cursor: pointer;
}