#textField:focus::-webkit-input-placeholder {
  color: #F49920 !important;
}
#message:focus::-webkit-input-placeholder {
  color: #F49920 !important;
}

#message {
  color: #ffff
}

#textField {
  padding: 20px !important;
  color: #ffff
}

#textField::-webkit-input-placeholder {
  color: #8C8C8C !important;
}

/* Chrome, Safari, Edge, Opera */
#textField::-webkit-outer-spin-button,
#textField::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}